import React, { useState } from 'react';
import '../styles/ExamInfoPage.scss';

interface SeatInfo {
  campus_name: string;
  building_name: string;
  floor_name: string;
  house_name: string;
  number: string;
  student_type_name: string;
  department_type_name: string;
  teacher_name: string;
}

const StudentSeatPage: React.FC = () => {
  const [studentName, setStudentName] = useState('');
  const [studentId, setStudentId] = useState('');
  const [seatInfo, setSeatInfo] = useState<SeatInfo | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setSeatInfo(null);
    setErrorMessage('');

    try {
      const response = await fetch(
        `https://express.saroz.top/search/seat/student?student_name=${encodeURIComponent(studentName)}&student_id=${encodeURIComponent(studentId)}`
      );
      const data = await response.json();

      if (data.code === 0) {
        setSeatInfo(data.data);
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      setErrorMessage('查询失败，请稍后再试');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="exam-info-page">
      <div className="container">
        <h1 className="title">学生工位查询系统</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label className="input-label" htmlFor="studentId">学生学号</label>
            <input
              className="input-field"
              id="studentId"
              type="text"
              value={studentId}
              onChange={(e) => setStudentId(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label className="input-label" htmlFor="studentName">学生姓名</label>
            <input
              className="input-field"
              id="studentName"
              type="text"
              value={studentName}
              onChange={(e) => setStudentName(e.target.value)}
              required
            />
          </div>
          <button className="query-button" type="submit" disabled={isLoading}>
            {isLoading ? '查询中...' : '查询'}
          </button>
        </form>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {seatInfo && (
          <div className="exam-info">
            <h2 className="title">工位信息</h2>
            <div className="info-item">
              <span className="info-label">校区:</span>
              <span className="info-value">{seatInfo.campus_name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">楼栋:</span>
              <span className="info-value">{seatInfo.building_name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">楼层:</span>
              <span className="info-value">{seatInfo.floor_name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">房间号:</span>
              <span className="info-value">{seatInfo.house_name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">工位号:</span>
              <span className="info-value">{seatInfo.number}</span>
            </div>
            <div className="info-item">
              <span className="info-label">学生类型:</span>
              <span className="info-value">{seatInfo.student_type_name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">所属系部:</span>
              <span className="info-value">{seatInfo.department_type_name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">导师姓名:</span>
              <span className="info-value">{seatInfo.teacher_name}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentSeatPage;