import React from 'react';
import ExamInfoPage from './components/ExamInfoPage';

function App() {
  return (
    <div className="App">
      <ExamInfoPage />
    </div>
  );
}

export default App;